<!--  -->
<template>
  <div>
    <div>

      <div class="qiepian">
        <div class="div-prompt" v-loading="loading">
          <el-upload action="" :show-file-list="false" :before-upload="beforeUpload" :http-request="httpRequest">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/uploadfile1.png" class="img1">
            <p class="p1">点击上传文件</p>
            <p class="p2">可以上传以下格式(docx、txt、pdf)的文件，一次可以上传多个文件。文档大小限制为 20MB。</p>
            <p class="p2">如果您有较大的文档，建议将它们拆分并单独上传。</p>
          </el-upload>

        </div>
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianka.png" class="qiepian-img" @click="jumbQt">
      </div>



      <div class="list-title">
        <p class="list-title-p1">文件列表</p>
        <p class="list-title-p2">（{{ fileCount }}/{{ fileCountMax }}）</p>
      </div>
      <div class="div-tabel">
        <div class="tabel-title">
          <p style="flex: 2;">文件名称</p>
          <p style="flex: 1;">上传时间</p>
          <p style="flex: 1;">状态</p>
          <p style="flex: 1;">操作</p>
        </div>
        <div class="table-list">
          <div class="tabel-content" v-for="(item, index) in fileList" :key="index">
            <p style="flex: 2;">{{ item.fileName }}</p>
            <p style="flex: 1;">{{ item.createDateLabel }}</p>
            <div class="tabel-status">

              <div class="tabel-tip" v-if="item.analysis == 0">

                <i class="el-icon-success" style="color:#37D07D ;"></i>
                <p>成功</p>
              </div>
              <div class="tabel-tip" v-else-if="item.analysis == 1">

                <i class="el-icon-warning" style="color:#F25E3E ;"></i>
                <p>解析失败</p>
              </div>

              <div class="tabel-tip" v-else>

                <i class="el-icon-info" style="color:#226CFF ;"></i>
                <p>正在解析</p>
              </div>
            </div>
            <div class="btn-box">
              <div class="btn-download" @click="downloadFile(item.fileName, item.fileUrl)">下载</div>
              <div class="btn-delete" @click="deleteConfim(index)">删除</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="show" :show-close="false">
      <div class="div-dialog">
        <div class="dialog-head">
          <p class="dialog-head-p">操作提示</p>
          <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="show = false">
        </div>

        <div class="dialog-content">

          <p>{{ dialogcontent }}</p>

        </div>

        <div class="dialog-bottom">

          <button class="dialog-negative" @click="show = false">取消</button>
          <button class="dialog-positive" @click="dialogConfim">确认</button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>

import { empFileListV2, knowledgeFileV1, deleteFile, fileStatusV1 } from "api/aiworker/aiworker.js";
import common from "common/js/common";
export default {
  data () {
    return {
      fileList: [],
      empId: null,
      fileCount: 0,
      fileCountMax: 0,
      loading: false,
      show: false,
      index: -1,
      dialogcontent: "是否确定删除此文件？",
      editFlag: null,
      

    }
  },
  methods: {
    jumbQt () {
      this.$router.push({ path: '/qa?eid=' + this.$Base64.encode(this.empId + "")})
    },

    httpRequest (file2) {
      this.loading = true;

      let fd = new FormData();
      fd.append('empId', this.empId);
      fd.append('userId', this.$localstore.get("hdUserId"));
      fd.append('access_token', this.$localstore.get("access_token"));
      fd.append('files', file2.file)
      knowledgeFileV1(fd).then((res) => {
        this.loading = false;
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '上传成功,等待文件解析...',
            type: 'success'
          });
          this.refreshFileList();
          this.refreshFileStatusV1(res.fileId);
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          } else {
            this.$message.error('上传失败，请重试！');
          }

        }
      }).catch((err) => {
        this.loading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });
    },
    beforeUpload (file) {
      //   const isJPG = file.type === 'image/jpeg';
      let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (this.fileCount >= this.fileCountMax) {
        this.$message.error('您的上传文档次数已用完');
        return false;
      }

      if (file.size / 1024 / 1024 < 20) {

        return true;
      } else {
        this.$message.error('上传文档大小不能超过20M！');
        return false;
      }

      /*
            if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'txt' || fileExtension === 'pdf') {
              // 处理符合要求的文件
              if (file.size / 1024 / 1024 < 20) {
      
                return true;
              } else {
                this.$message.error('上传文档大小不能超过20M！');
                return false;
              }
      
            } else {
              this.$message.error('请上传正确的文档格式');
              return false;
            } */
    },

    downloadFile (urlName, url1) {
      const link = document.createElement('a');
      console.log(urlName + '--');
      link.download = urlName // 下载文件的名称
      link.href = url1;

      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    refreshFileList () {
      empFileListV2(this.empId).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.fileList = res.data;
          this.fileCount = res.fileInfo.fileCount;
          this.fileCountMax = res.fileInfo.fileCountMax;
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err));
        }
      });
    },

    deleteConfim (i) {
      this.show = true
      this.index = i;
    },

    refreshFileStatusV1 (id1) {
      setTimeout(() => {
        fileStatusV1(id1).then((res) => {
          console.log(res);
          if (res.code == 200) {

            if (res.data == 0) {
              this.$message({
                message: '文件解析成功',
                type: 'success'
              });
              this.refreshFileList();

            } else if (res.data == 1) {
              this.$message({
                message: '文件解析失败',
                type: 'error'
              });
              this.refreshFileList();

            } else {
              this.refreshFileStatusV1(id1);
            }
          }


        }).catch((err) => {

        });

      }, 3000);
    },

    dialogConfim () {
      this.show = false;
      deleteFile(this.fileList[this.index].id, this.empId).then((res) => {

        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.refreshFileList();
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });

    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

   
    this.editFlag = this.$route.query.flag;
    this.empId = this.$Base64.decode(this.$route.query.eid);
    sessionStorage.setItem('empId', this.empId);
    sessionStorage.setItem('editFlag', this.editFlag)

    empFileListV2(this.empId).then((res) => {

      console.log(res);
      if (res.code == 200) {
        this.fileList = res.data;
        this.fileCount = res.fileInfo.fileCount;
        this.fileCountMax = res.fileInfo.fileCountMax;
      } else if (res.code == 400) {
        if (!common.isStringEmpty(res.message)) {
          this.$message.error(res.message);
        } else {
          this.$message.error('文档解析错误，请查看或修改文档内容');
        }


      } else {

        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }
      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.qiepian {
  width: 100%;
  height: 240px;
  margin-bottom: 16px;

  display: flex;

}

.div-prompt {
  background: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px dashed #D7E4F3;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qiepian-img {
 
  width: 360px;
  height: 240px;
  margin-left: 20px;
}

.img1 {
  width: 190px;
  height: 140px;
}

.p1 {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #226CFF;
  line-height: 18px;
  margin-bottom: 10px;
}

.p2 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 18px;
}

.list-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.list-title-p1 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
}

.list-title-p2 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.3);
  line-height: 14px;
  margin-left: 6px;
}

.div-tabel {
  width: 100%;
  height: 420px;
  margin-bottom: 30px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;

}

.tabel-title {
  width: 100%;
  display: flex;
  height: 50px;
  border-radius: 10px 10px 0px 0px;
  background: #F9FAFB;
  align-items: center;
}

.tabel-title p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 20px;
}

.table-list {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.tabel-content {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #E9EEF4;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tabel-content p {
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-left: 20px;
}

.btn-box {
  flex: 1;
  padding-left: 20px;
  display: flex;
}

.tabel-status {
  flex: 1;
  display: flex;
}

.tabel-progress {
  width: 70%;
}

.tabel-tip {
  display: flex;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0px;
}


.tabel-tip i {

  margin-right: -18px;

}

.btn-download {
  width: 70px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #EAF1FA;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #226CFF;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

.btn-delete {
  width: 70px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #FCF0E6;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #F44924;
  text-align: center;
  margin-left: 10px;
  line-height: 38px;
  cursor: pointer;
}


.div-dialog {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content {
  display: flex;
  width: 100%;
  flex: auto;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;


}

.dialog-bottom {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}



/deep/.el-dialog {

  width: 400px;
  height: 260px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  height: 100%;
  padding: 0;
}

.pop-content {
  display: flex;
}
</style>